import React, { useRef } from "react";
import moment from "moment";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { SearchOutlined } from "@ant-design/icons";
import { Col, Input, message, Row, Space, Table, Select } from "antd";

import { FetchApi } from "../../api";
import {
  BlockUI,
  LoadingView,
  Container,
  ErrorView,
  ButtonCustom,
  AlertService,
  SvgView,
} from "../../elements";
import { Convert, Validate } from "../../elements/Validate";
import { FilterByTime } from "../../elements/FilterByTime";
import { Svgs, STRINGS, LOGIC } from "../../constants";
import PaginationWithSizeChange from "../../elements/PaginationWithSizeChange";
import Auth from "../../store/Authentication";

import "../../util/variables.scss";

const formatsu = "YYYY-MM-DD";
const Option = Select.Option;

const HistoryPointStore = () => {
  const { id } = useParams();
  const blockUI = useRef();
  const page = useRef(1);
  const size = useRef(10);
  const startTimeRef = useRef(moment().subtract(1, "days").format(formatsu));
  const endTimeRef = useRef(moment().format(formatsu));
  const searchText = useRef();
  const pointType = useRef("");
  const timeout = useRef();
  const userAdmin = JSON.parse(Auth.getCurrentUser());

  const { data, refetch, isLoading } = useQuery([`HistoryStore`], () =>
    FetchApi.getHistoryPointStoreAdmin({
      size: size.current,
      page: page.current,
      startDate: startTimeRef.current,
      endDate: endTimeRef.current,
      nameSearch: searchText.current,
      pointType: pointType.current,
      storeId: id || userAdmin.storeId,
    })
  );

  const columns = [
    {
      title: "ID",
      dataIndex: "customerId",
      key: "customerId",
      align: "center",
    },

    {
      title: STRINGS.user_name,
      dataIndex: "customerName",
      key: "customerName",
      align: "center",
      render: (e) => Convert.removeSpaceName(e),
    },
    {
      title: STRINGS.furigana_name,
      dataIndex: "furiganaName",
      key: "furiganaName",
      align: "center",
      render: (e) => Convert.removeSpaceName(e),
    },
    {
      title: STRINGS.staff_in_charge,
      dataIndex: "staffName",
      key: "staffName",
      align: "center",
    },
    {
      title: STRINGS.staff_id,
      dataIndex: "staffId",
      key: "staffId",
      align: "center",
    },
    {
      title: STRINGS.time_confirm,
      dataIndex: "timeConfirm",

      key: "timeConfirm",
      render: (timeConfirm, row) => {
        if (!timeConfirm) {
          return moment(row.createdAt).format(LOGIC.timeFormat);
        }
        return moment(timeConfirm).format(LOGIC.timeFormat);
      },
      align: "center",
    },
    {
      title: STRINGS.type,
      dataIndex: "pointType",
      key: "pointType",
      align: "center",
      render: (pointType) => {
        if (pointType === "use_point") {
          return STRINGS.type_pt_use;
        }
        if (pointType === "review_point") {
          return STRINGS.review_point;
        }
        if (pointType === "gacha_point") {
          return STRINGS.lottery_point;
        }
        if (pointType === "check_in_point") {
          return STRINGS.visit_point;
        }
        if (pointType === "special") {
          return STRINGS.special_point;
        }
        if (pointType === "booking_point") {
          return STRINGS.booking_type_point;
        }
        if (pointType === "welcome_point") {
          return STRINGS.welcome_point;
        }
        return "";
      },
    },
    {
      title: STRINGS.award_name,
      key: "pointName",
      align: "center",
      render: (record) => {
        const pointName = record?.bookingPointName || record?.gachaName;
        return pointName;
      },
    },
    {
      title: STRINGS.points,
      dataIndex: "numberOfPoints",
      key: "numberOfPoints",
      align: "center",
    },
    {
      title: STRINGS.review_evaluation_score,
      dataIndex: "totalRating",
      key: "totalRating",
      align: "center",
    },
    {
      key: "action",
      render: (text, record) => {
        if (
          record.pointType === "check_in_point" ||
          record.pointType === "special"
        ) {
          return (
            <Space size={10}>
              <button
                style={{
                  border: 0,
                  backgroundColor: "transparent",
                  cursor: "pointer",
                }}
                onClick={onRemove(record)}
              >
                <SvgView svgData={Svgs.delete} />
              </button>
            </Space>
          );
        }
        return null;
      },
      fixed: "right",
      width: 60,
    },
  ];

  const onRemove = (item) => () => {
    AlertService.set({
      title: STRINGS.confirm,
      textConfirm: STRINGS.delete,
      content: STRINGS.confirm_delete_common,
      onConfirm: async () => {
        let result = await FetchApi.deleteHistoryVisitUser(item.id);
        if (result.success) {
          refetch();
          message.success(STRINGS.delete_success);
        } else {
          message.warning(result.message || STRINGS.delete_failed);
        }
      },
    });
  };

  const onSearchByText = (text) => {
    searchText.current = Validate.removeSpecialCharactors(text.target.value);
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    timeout.current = setTimeout(() => {
      if (text === "") {
        searchText.current = null;
      }
      page.current = 1;
      refetch();
    }, 800);
  };

  const renderLeft = () => {
    return (
      <Row align="bottom" style={{ marginBottom: 4 }}>
        <Col>
          <FilterByTime
            key={endTimeRef.current + startTimeRef.current}
            label={STRINGS.start_time}
            onChange={(e) => (startTimeRef.current = e)}
            picker="date"
            format={LOGIC.dateFormat}
            value={startTimeRef.current}
            formatOnChange={formatsu}
            allowClear={false}
          />
        </Col>
        <Col style={{ margin: "0px 10px" }}>
          <FilterByTime
            key={endTimeRef.current + startTimeRef.current}
            picker="date"
            label={STRINGS.end_time}
            onChange={(e) => (endTimeRef.current = e)}
            format={LOGIC.dateFormat}
            formatOnChange={formatsu}
            value={endTimeRef.current}
            allowClear={false}
          />
        </Col>
        <ButtonCustom
          onClick={() => {
            if (
              moment(endTimeRef.current).isBefore(moment(startTimeRef.current))
            ) {
              const end = endTimeRef.current;
              endTimeRef.current = startTimeRef.current;
              startTimeRef.current = end;
            }
            refetch();
          }}
        >
          {STRINGS.filter}
        </ButtonCustom>
        <Col style={{ margin: "0px 10px" }}>
          <Row>{STRINGS.type_pt_history}</Row>
          <Select
            defaultValue=""
            style={{ width: 146 }}
            onChange={(e) => {
              pointType.current = e;
              page.current = 1;
              refetch();
            }}
          >
            <Option value="">{STRINGS.all}</Option>
            <Option value="gacha_point">{STRINGS.lottery_point}</Option>
            <Option value="check_in_point">{STRINGS.visit_point}</Option>
            <Option value="use_point">{STRINGS.type_pt_use}</Option>
            <Option value="review_point">{STRINGS.review_point}</Option>
            <Option value="special">{STRINGS.special_point}</Option>
            <Option value="booking_point">{STRINGS.booking_type_point}</Option>
            <Option value="welcome_point">{STRINGS.welcome_point}</Option>
          </Select>
        </Col>
        <ButtonCustom
          onClick={() =>
            FetchApi.downloadHistoryVisit({
              startDate: startTimeRef.current,
              endDate: endTimeRef.current,
              nameSearch: searchText.current,
              pointType: pointType.current,
              storeId: id || userAdmin.storeId,
            })
          }
        >
          {STRINGS.download}
        </ButtonCustom>
      </Row>
    );
  };
  const renderRight = () => {
    return (
      <Row align="bottom">
        <Col>
          <div>{STRINGS.total_store_points}</div>
          <Input
            className="input-point-disabled"
            key={data?.totalSendPointStore}
            style={{ borderRadius: 4, width: 140 }}
            value={data?.totalSendPointStore}
            disabled
          />
        </Col>
        <Col style={{ margin: "0px 10px" }}>
          <div>{STRINGS.total_points_used}</div>
          <Input
            className="input-point-disabled"
            style={{ borderRadius: 4, width: 140 }}
            value={data?.totalUsePointStore}
            disabled
          />
        </Col>
        <Col>
          <Input
            onChange={onSearchByText}
            style={{
              borderRadius: 4,
              width: 200,
              flexDirection: "row-reverse",
              paddingLeft: 4,
            }}
            suffix={<SearchOutlined style={{ color: "#BFBFBF" }} />}
            placeholder={STRINGS.search_pt_history_place_holder}
          />
        </Col>
      </Row>
    );
  };

  if (isLoading) {
    return <LoadingView />;
  }

  if (data && !data.success) {
    return <ErrorView data={data} />;
  }

  return (
    <Container
      renderLeft={renderLeft}
      renderRight={renderRight}
      childrenStyle={{ marginTop: 10 }}
    >
      <BlockUI ref={blockUI} />
      <Table
        loading={isLoading}
        rowKey={(record) =>
          record.customerId + record.timeConfirm + record.timeVisit + record.id
        }
        columns={columns}
        dataSource={data?.pointRequestDTOS}
        locale={{ emptyText: STRINGS.no_data }}
        pagination={false}
        scroll={{ x: 1500, y: "calc(100vh - 320px)" }}
      />
      <PaginationWithSizeChange
        size={size.current}
        total={data?.pageDto?.totalElements || 0}
        onChange={(pageNumber) => {
          page.current = pageNumber;
          refetch();
        }}
        onShowSizeChange={(e) => {
          size.current = e.size;
          page.current = 1;
          refetch();
        }}
        current={page.current}
      />
    </Container>
  );
};

export default HistoryPointStore;
